<template>
    <div style="background-color: #BABBBF;width: 100vw;height: 100vh;min-width: 1030px;min-height: 910px;">
        <div style="height: 1px;"></div>
        <div class="faCla">
            <div class="fa1">
                网易云在线客服
            </div>
            <div class="fa2">
                <div style="height: 1px;"></div>
                <div style="height: 575px;overflow: hidden scroll;" class="funCla">


                    <div class="fa3">
                        <div style="text-align: right;height: 100%;position: relative;">
                            <div style="display: inline-block;width: 85%;vertical-align: top;">

                                <div class="fa4"
                                    style="background: #F96868;color: #ffffff; max-width: 85%;margin-right: 11px;padding: 4px 13px;">
                                    转人工
                                </div>
                                <div style="color: #95959c;width: 98%;line-height: 22px;">今天 {{ time }}</div>

                            </div>

                            <div class="bubble_arrow_right"></div>
                            <img src="@/assets/wyun.png" alt="" srcset=""
                                style="width: 36px;height: 36px;;vertical-align: top;border-radius: 50%;margin-right: 8px;background: #F96868; ">
                        </div>
                    </div>

                    <!-- <div class="fa3">
                    <div style="text-align: left;height: 100%;position: relative;">
                        <img src="@/assets/kfyy.png" alt="" srcset=""
                            style="width: 36px;height: 36px;;vertical-align: top;border-radius: 50%;margin-left: 10px;background: #F96868;">
                        <div class=" bubble_arrow_left"></div>
                        <div class="fa4"
                            style="background: #fff;color: #000; max-width: 27%;margin-left: 9px;line-height: 21px; padding: 7px 13px;">
                            亲爱的用户，请先简要说明您遇到的问题，小艺才能更好地为您转接到合适的人工客服协助您解决问题。
                        </div>
                    </div>
                </div> -->



                    <div style="display: flex;align-items: center;justify-content: center;">
                        <span
                            style="text-align: center;font-size: 12px;margin-top: 10px;background: rgb(202, 203, 204);padding: 2px 9px;color: rgb(255, 255, 255);border-radius: 22px;">
                            您已进入人工客服
                        </span>
                    </div>



                    <div class="fa3">
                        <div style="text-align: left;height: 100%;position: relative;">
                            <img src="@/assets/yypng.png" alt="" srcset=""
                                style="width: 36px;height: 36px;;vertical-align: top;border-radius: 50%;margin-left: 10px;background: #fff;">
                            <div class=" bubble_arrow_left" style="top: 38px;"></div>
                            <div style="display: inline-block;width: 85%;vertical-align: top;">
                                <div style="margin-left: 13px;height: 26px;color: #000;">{{ num }}</div>
                                <div class="fa4"
                                    style="background: #fff;color: #000; max-width: 55%;margin-left: 9px;line-height: 21px; padding: 11px 13px;">
                                    您已 <span style="color:#F96868;font-weight: 600;">进入人工客服</span> ，请描述您的问题
                                </div>
                                <div style="color: #95959c;width: 98%;line-height: 22px;margin-left: 9px;">今天 {{ times
                                    }}
                                </div>

                            </div>

                        </div>
                    </div>


                    <div class="fa3">
                        <div style="text-align: left;height: 100%;position: relative;">
                            <img src="@/assets/yypng.png" alt="" srcset=""
                                style="width: 36px;height: 36px;;vertical-align: top;border-radius: 50%;margin-left: 10px;background: #fff;margin-top: 5px;">
                            <div class=" bubble_arrow_left" style="top: 38px;"></div>
                            <div style="display: inline-block;width: 85%;vertical-align: top;">
                                <div style="margin-left: 13px;height: 26px;color: #000;">{{ num }}</div>
                                <div class="fa4"
                                    style="background: #fff;color: #000; max-width: 74%;margin-left: 9px;line-height: 21px; padding: 11px 13px;">
                                    您好，工号{{ num }}为您提供服务。小客服已经加速赶到，立即帮您处理哟，请问有什么可以帮您~
                                </div>
                                <div style="color: #95959c;width: 98%;line-height: 22px;margin-left: 9px;">今天 {{ times
                                    }}
                                </div>

                            </div>

                        </div>
                    </div>


                    <div class="fa3" style="margin: 12px 0px;">
                        <div style="text-align: right;height: 100%;position: relative;">

                            <div style="display: inline-block;width: 85%;vertical-align: top;">

                                <div class="fa4"
                                    style="background: #F96868;color: #ffffff; max-width: 77%;margin-right: 11px;padding: 9px 13px;">
                                    <p style="line-height: 24px;text-align: left;" v-if="inputFla"
                                        @click="inputFla = false">{{ inputText || '自定义信息' }}
                                    </p>
                                    <p v-else>
                                        <el-input v-model="inputText" @keydown.enter="inputFla = true"
                                            @change="inputFla = true" style="width: 100%;"></el-input>
                                    </p>
                                </div>
                                <div style="color: #95959c;width: 98.5%;line-height: 22px;">今天 {{ times }} <span
                                        style="padding-left: 5px;">已读</span> </div>

                            </div>

                            <div class="bubble_arrow_right"></div>
                            <img src="@/assets/wyun.png" alt="" srcset=""
                                style="width: 36px;height: 36px;;vertical-align: top;border-radius: 50%;margin-right: 8px;background: #F96868; ">

                        </div>
                    </div>


                    <div class="fa3">
                        <div style="text-align: left;height: 100%;position: relative;">
                            <img src="@/assets/yypng.png" alt="" srcset=""
                                style="width: 36px;height: 36px;;vertical-align: top;border-radius: 50%;margin-left: 10px;background: #fff;margin-top: 5px;">
                            <div class=" bubble_arrow_left" style="top: 38px;"></div>
                            <div style="display: inline-block;width: 85%;vertical-align: top;">
                                <div style="margin-left: 13px;height: 26px;color: #000;">{{ num }}</div>
                                <div class="fa4"
                                    style="background: #fff;color: #000; max-width: 72%;margin-left: 9px;line-height: 21px; padding: 11px 13px;">
                                    辛苦亲亲先提供一下充值的平台名称，小客服帮您核实。
                                </div>
                                <div style="color: #95959c;width: 98%;line-height: 22px;margin-left: 9px;">今天 {{ times
                                    }}
                                </div>

                            </div>

                        </div>
                    </div>


                    <div class="fa3" style="margin: 12px 0px;">
                        <div style="text-align: right;height: 100%;position: relative;">

                            <div style="display: inline-block;width: 85%;vertical-align: top;">

                                <div class="fa4"
                                    style="background: #F96868;color: #ffffff; max-width: 77%;margin-right: 11px;padding: 9px 13px;">
                                    <p style="line-height: 24px;text-align: left;" v-if="inputShopFla"
                                        @click="inputShopFla = false">{{ inputShop || '自定义信息' }}
                                    </p>
                                    <p v-else>
                                        <el-input v-model="inputShop" @keydown.enter="inputShopFla = true"
                                            @change="inputShopFla = true" style="width: 100%;"></el-input>
                                    </p>
                                </div>
                                <div style="color: #95959c;width: 98.5%;line-height: 22px;">今天 {{ times }} <span
                                        style="padding-left: 5px;">已读</span> </div>

                            </div>

                            <div class="bubble_arrow_right"></div>
                            <img src="@/assets/wyun.png" alt="" srcset=""
                                style="width: 36px;height: 36px;;vertical-align: top;border-radius: 50%;margin-right: 8px;background: #F96868; ">

                        </div>
                    </div>


                    <!-- 
                <div class="fa3" style="margin: 12px 0px;">
                    <div style="text-align: right;height: 100%;">
                        <div class="fa4"
                            style="background: #00b32d;color: #ffffff; max-width: 85%;margin-right: 9px;padding:3px 10px;">
                            <p v-if="inputShop" @click="inputShop = false">{{ inputshop || '自定义信息' }}
                            </p>
                            <p v-else>
                                <el-input v-model="inputshop" @keydown.enter="inputShop = true"
                                    @change="inputShop = true" style="width: 100%;"></el-input>
                            </p>

                        </div>
                        <img src="@/assets/wyun.png" alt="" srcset=""
                            style="width: 36px;height: 36px;;vertical-align: top;border-radius: 50%;margin-right: 8px;margin-top: 5px; ">
                    </div>
                </div> -->

                    <div class="fa3">
                        <div style="text-align: left;height: 100%;position: relative;">
                            <img src="@/assets/yypng.png" alt="" srcset=""
                                style="width: 36px;height: 36px;;vertical-align: top;border-radius: 50%;margin-left: 10px;background: #fff;margin-top: 5px;">
                            <div class=" bubble_arrow_left" style="top: 38px;"></div>
                            <div style="display: inline-block;width: 85%;vertical-align: top;">
                                <div style="margin-left: 13px;height: 26px;color: #000;">{{ num }}</div>
                                <div class="fa4"
                                    style="background: #fff;color: #000; max-width: 72%;margin-left: 9px;line-height: 21px; padding: 11px 13px;">
                                    辛苦亲亲，已为您查询，此账号已充值到账
                                </div>
                                <div style="color: #95959c;width: 98%;line-height: 22px;margin-left: 9px;">今天 {{ times
                                    }}
                                </div>

                            </div>

                        </div>
                    </div>

                </div>


                <div style="position: absolute;bottom: 159px;width: 100%;">
                    <div
                        style="display: flex;background: #fff;height: 42px;line-height: 38px;border-bottom: 1px solid #EDEEF0">
                        <!-- <div class="sa1" style="margin-left:16px ;">
                            我的订单
                        </div>
                        <div class="sa1">
                            黑胶福利券
                        </div>
                        <div class="sa1">
                            退订引流
                        </div>
                        <div class="sa1">
                            开发票
                        </div>
                        <div class="sa1">
                            查询工单
                        </div> -->

                        <div class="son3">
                            <img src="@/assets/axiaoll.png" alt="" srcset="">
                        </div>
                        <div class="son3">
                            <img src="@/assets/xccc.png" alt="" srcset="">
                        </div>
                        <div class="son3">
                            <img src="@/assets/wjsc.png" alt="" srcset="" style="">
                        </div>
                        <div class="son3">
                            <img src="@/assets/jianqie.png" alt="" srcset="">
                        </div>

                    </div>

                    <div style="display: flex;width: 740px;height: 42px;background: #fff;width: 100%;">

                        <!-- <div style="width: 5%;margin-top: 5px;">
                            <img src="@/assets/jianpan.png" alt="" srcset="" style="width: 34px;">
                        </div> -->

                        <div style="width: 100%;margin-top: 5px;height: 145px;background-color: #fff;position: relative;"
                            class="Son1">
                            <el-input v-model="shuru" placeholder="输入消息..." style="width: 100%;"></el-input>


                            <div class="son2">
                                发送
                            </div>

                            <div style="position:absolute;bottom: 0px;text-align: center;width: 100%;color: #BFBFBF;">
                                <img src="@/assets/yvyvvy.png" alt="" srcset="" style="vertical-align: sub;">
                                七鱼提供服务支持
                            </div>
                        </div>


                        <!-- <div style="margin-top: 5px;">
                            <img src="@/assets/xiao.png" alt="" srcset="" style="width: 34px;">
                            <img src="@/assets/jia.png" alt="" srcset="" style="width: 34px;">
                        </div> -->

                    </div>

                </div>




            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'kefu',
    data() {
        return {
            time: this.formatDate2(new Date),
            times: this.formatDate3(new Date),
            inputText: '',
            inputShop: '',
            inputFla: true,
            inputShopFla: true,
            shuru: '',
            num: Math.floor(Math.random() * 10000)
        }
    },

    mounted() {
    },

    methods: {
        formatDate2(now) {
            let year = now.getFullYear(); //取得4位数的年份
            let month = now.getMonth() + 1; //取得日期中的月份，其中0表示1月，11表示12月
            let date = now.getDate(); //返回日期月份中的天数（1到31）
            let hour = now.getHours(); //返回日期中的小时数（0到23）
            let minute = now.getMinutes() - 1; //返回日期中的分钟数（0到59）
            let seconds = now.getSeconds(); //返回日期中的秒数（0到59）
            // return year + "-" + (month <= 9 ? '0' + month : month) + "-" + (date <= 9 ? '0' + date : date) + " " + (hour <= 9 ? '0' + hour : hour) + ":" + (minute <= 9 ? '0' + minute : minute)
            //  + ":" + seconds;
            return (hour <= 9 ? '0' + hour : hour) + ":" + (minute <= 9 ? '0' + minute : minute)

        },
        formatDate3(now) {
            let year = now.getFullYear(); //取得4位数的年份
            let month = now.getMonth() + 1; //取得日期中的月份，其中0表示1月，11表示12月
            let date = now.getDate(); //返回日期月份中的天数（1到31）
            let hour = now.getHours(); //返回日期中的小时数（0到23）
            let minute = now.getMinutes(); //返回日期中的分钟数（0到59）
            let seconds = now.getSeconds(); //返回日期中的秒数（0到59）
            return (hour <= 9 ? '0' + hour : hour) + ":" + (minute <= 9 ? '0' + minute : minute)
            //  + ":" + seconds;
        },
    }
}
</script>

<style lang="less" scoped>
.faCla {
    width: 826px;
    margin: 80px auto;
    height: 84vh;
    max-height: 800px;
    overflow: hidden;
    border-radius: 11px;
    min-height: 820px;
}

.fa1 {
    height: 50px;
    line-height: 50px;
    background: #F96868;
    font-size: 15px;
    padding-left: 25px;
    color: #fff;
    border-radius: 10px 10px 0px 0px;

}

.fa2 {
    background: #EDEEF0;
    height: 100%;
    position: relative;
}

.fa3 {
    min-height: 36px;
    line-height: 36px;
    margin-top: 10px;
}

.fa4 {
    display: inline-block;
    font-size: 14px;
    width: auto;
    border-radius: 7px;

}

.sa1 {
    color: #000;
    background-color: #fff;
    padding: 5px 12px;
    border-radius: 23px;
    font-size: 13px;
    margin-left: 11px;
    border: 1px solid rgb(249 104 104);
    height: 28px;
    line-height: 17px;
    margin-top: 8px;
}

.Son1 /deep/ .el-input__inner {
    background: #fff;
    color: #8e939e;
    border: 1px solid #fff;
}

// .Son1 /deep/ .el-input .is-disabled {
//     background: #F5F7FA;
//     color: blue;
//     border: 1px solid #fff;

// }
.Son1 /deep/input::-webkit-input-placeholder {
    color: #9094AF;
    // font-size: 15px;
}

.bubble_arrow_right {

    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 6px solid #F96868;
    right: 49px;
    position: absolute;
    top: 13px;
    content: "";

}

.bubble_arrow_left {
    position: absolute;
    top: 13px;
    content: "";
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-right: 6px solid #fff;
    left: 49px;

}


.funCla::-webkit-scrollbar {
    display: none;
}

.son3 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;


}

.son2 {
    position: absolute;
    bottom: 27px;
    color: rgb(255, 255, 255);
    height: 27px;
    line-height: 27px;
    width: 64px;
    text-align: center;
    font-size: 15px;
    background: rgb(251, 164, 164);
    right: 6px;
    right: 12px;
    letter-spacing: 2px;
}
</style>
